// /src/context/UserContext.js
import React,{ createContext, useContext, useState } from "react";

export const CommonContext = createContext();

export const CommonProvider = ({ children }) => {


  const [isOpen, setisOpen] = useState([]); // admin 페이지에서 navigation 메뉴 관리
 
  return (
    <CommonContext.Provider value={{  isOpen }}>
      {children}
    </CommonContext.Provider>
  );
};

export const useCommonContext = () => {
  return useContext(CommonContext);
};
