import React from "react";
import Spinner from '../assets/gif/loading.gif'

const Loading = () => {
  return (
      <div className="loading-wrapper custom-align-item custom-flex-item custom-justify-center">
        <img src={Spinner} alt="loading"/>
      </div>
  )
}

export default Loading;

