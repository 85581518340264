import React, { useContext } from "react";
import { detectUserAgent } from "./utils/commonFunctions";

// 채팅 프롬프트
const Welcome = React.lazy(() => import('./views/chat/Welcome'))
const Main = React.lazy(() => import('./views/chat/Main'))
const Play = React.lazy(() => import('./views/chat/Play'))

let user_agent = detectUserAgent()
const routes = [
  {
    path: '/',
    exact: true,
    name: '웰컴',
    element: user_agent === 'pc' ? Welcome : Main,
    navigation: false,
    children: []
  },
  {
    path: '/chat',
    exact: true,
    name: '메인',
    element: Main,
    navigation: false,
    children: []
  },
  {
    path: '/play365',
    exact: true,
    name: '체험365',
    element: Play,
    navigation: false,
    children: []
  },
  {
    path: '/iframe',
    exact: true,
    name: 'iframe',
    element: Main,
    navigation: false,
    children: []
  },
]
export default routes;