import { BrowserRouter as Router, Route, Routes, useLocation, useParams, useNavigate } from "react-router-dom";
import { Suspense, useEffect, useRef, useState } from "react";
import './App.css';
import routes from "./routes";
import { NavermapsProvider } from "react-naver-maps";
// context
import { CommonContext } from "./context/CommonContext";

// component
import Loading from "./components/Loading";
import NotFound from "./views/common/NotFound";
// import SideBar from "./components/SideBar";

// utils
import { detectUserAgent, detectWindowWidth, getLoginInfo } from "./utils/commonFunctions";

const ADMIN_URL = 'admin';
const MAP_KEY = process.env.REACT_APP_MAP_PUB_KEY;

function App() {

  const navigation = useNavigate();

  const [user, setUser] = useState({
    user_id: '',
    is_admin: false,
    access_token: '',
    token_type: '',

  })
  const [isLogin, setIsLogin] = useState(false);
  const [isMobile, setIsMobile] = useState(false)
  const [isGuide, setIsGuide] = useState(false)
  const [isOpenStates, setIsOpenStates] = useState([...routes]);
  const controllerRef = useRef(new AbortController())
  const [isUpdate, setIsUpdate] = useState(false);

  const toggleOpen = (index) => {
    const newIsOpenStates = [...isOpenStates];
    newIsOpenStates[index].isOpen = !newIsOpenStates[index].isOpen;
    setIsOpenStates(newIsOpenStates);
  };

  const [history, setHistory] = useState([]);

  const [openNav, setOpenNav] = useState(true);  // 채팅 프롬프트에서 사이드바 토글 관리

  const toggleNav = () => {
    setOpenNav(!openNav);
  };

  // routes for children menus
  function flattenRoutes(routes) {
    return routes.reduce((acc, route) => {
      acc.push(route);
      if (route.children) {
        acc = acc.concat(flattenRoutes(route.children));
      }
      return acc;
    }, []);
  }

  // 어드민 Nav, 프롬프트 SideBar 사용
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname.includes(ADMIN_URL)) {
      setIsAdmin(true)
    }
    return () => {
      setIsAdmin(false);
    }

  }, [])


  useEffect(() => {
    if (user.user_id !== '') {
      getLoginInfo(setUser)
    } else {
      setIsLogin(false)
    }

  }, [isAdmin, navigation])


  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
  useEffect(() => {
    setScreenSize();
    const checkMobile = detectWindowWidth();
    setIsMobile(checkMobile)
  }, []);

  const handleResize = () => {

    getLoginInfo(setUser)
    const checkAgent = detectUserAgent();
    setIsMobile(checkAgent === 'mobile' ? true : false)
    return () => {
      setUser({
        user_id: '',
        is_admin: false,
        access_token: '',
        token_type: '',
        group_auth_name: '',
        group_id: -1
      })
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {

    document.body.style.height = '100vh';
    document.documentElement.style.height = '100vh';

    const rootElement = document.getElementById('root');
    const boxElement = document.querySelector('.chat-prompt__box__history');
    const mainElement = document.querySelector('main');

    if (rootElement && boxElement && mainElement) {
      rootElement.style.height = isMobile ? '100vh' : '';
      boxElement.style.height = isMobile ? 'calc(var(--vh, 1vh) * 100)' : '100vh';
      mainElement.style.height = isMobile ? '100vh' : '100vh';
      // mainElement.style.height = isMobile ? 'calc(var(--vh, 1vh) * 100)' : '100vh';
    }
  }, [isMobile, history.length]);

  return (
    <NavermapsProvider ncpClientId={MAP_KEY}>
      <CommonContext.Provider value={{ user, setUser, isOpenStates, isGuide, setIsGuide, toggleOpen, history, setHistory, openNav, toggleNav, isUpdate, setIsUpdate, isMobile, controllerRef }}>
        <Suspense fallback={<Loading />}>
          {/* <Style isAdmin={isAdmin}> */}
          <Routes>
            {
              routes.map((each, index) => {
                return (
                  <Route
                    key={`route-${index}`}
                    path={each.path}
                    Component={each.element}
                  />
                )
              })
            }
            {flattenRoutes(routes).map((each, index) => {
              return (

                <Route
                  key={`route-${index}`}
                  path={each.path}
                  element={<each.element />}
                />
              );
            })}
            <Route path="*" Component={NotFound} />
          </Routes>
          {/* </Style> */}
        </Suspense>
      </CommonContext.Provider>
    </NavermapsProvider>
  );
}

export default App;

// Nav 여닫게 할 시 재사용
// const Style = styled.div`
//   main {
//     width: ${props=>props.isAdmin ? 'calc(100% - 15rem); float: right;' : 'width:100%;'}
//   }
// `